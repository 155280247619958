.base_box {
  background: white;
  border-radius: 2px;
  border-color: #d9d9d9;
  -webkit-box-shadow: 0 25px 35px rgba(0, 0, 0, 0.07);
  box-shadow: 0 25px 35px rgba(0, 0, 0, 0.07);
}

.green_element {
  background-color: #04c778;
  color: white;
  border: none;

  &:hover {
    background-color: #04c778;
    color: white;
    border: none;
  }
}

.centerAbs {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.centerRltv {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

.form_item_margin {
  margin-bottom: 5px;
}

.title {
  float: left;
}

.button_group {
  margin: 10px 0 50px 0;
  button {
    margin-right: 10px;
  }
}

.adaptive_img {
  display: block;
  width: auto;
  height: auto;
}
