@import (reference) '../../assets/base/variables.less';

.home_button {
  width: 120px;
  height: 120px;
  background: #f2f2f2 !important;
  border-radius: 30px;
  border: 0px !important;
  transition: all 0.1s ease-in;
  margin: 0 auto;

  @media @sm {
    width: 150px;
    height: 150px;
  }

  @media @lg {
    width: 160px;
    height: 160px;
  }

  @media @xl {
    width: 175px;
    height: 175px;
  }
}

.home_button:hover {
  border: 3px solid #083b66 !important;
}

@primary-color: black;@card-padding-base: 15px;@layout-sider-background: #fff;@menu-bg: #fff;@border-radius-base: 2px;@collapse-content-bg: #fff;@collapse-header-bg: #fff;@collapse-panel-border-radius: 10px;@layout-header-background: #f7f9fa;@heading-color: black;@label-color: #000000a6;@layout-header-height: 50px;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@table-header-color: rgba(0,0,0,0.65);@tabs-vertical-margin: 0;@font-family: Kufam;