@import (reference) '../base/base.less';

.switch_lenguaje {
  margin-right: 80px;
  width: 100%;
}

.form_box_lenguaje {
  width: 100%;
  min-width: 100%;
  padding: 40px;
  max-width: 100px;
  background-color: #fff;
}

.form_box {
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  &:extend(.centerAbs);
}

#signin_header {
  font-family: arial;
}

#logoSVG {
  width: 85px;
}

@primary-color: black;@card-padding-base: 15px;@layout-sider-background: #fff;@menu-bg: #fff;@border-radius-base: 2px;@collapse-content-bg: #fff;@collapse-header-bg: #fff;@collapse-panel-border-radius: 10px;@layout-header-background: #f7f9fa;@heading-color: black;@label-color: #000000a6;@layout-header-height: 50px;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@table-header-color: rgba(0,0,0,0.65);@tabs-vertical-margin: 0;@font-family: Kufam;