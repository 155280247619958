.arrimo_link {
  font-family: Kufam;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: #083b66;
}

@primary-color: black;@card-padding-base: 15px;@layout-sider-background: #fff;@menu-bg: #fff;@border-radius-base: 2px;@collapse-content-bg: #fff;@collapse-header-bg: #fff;@collapse-panel-border-radius: 10px;@layout-header-background: #f7f9fa;@heading-color: black;@label-color: #000000a6;@layout-header-height: 50px;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@table-header-color: rgba(0,0,0,0.65);@tabs-vertical-margin: 0;@font-family: Kufam;