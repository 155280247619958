.arrimo_input {
  background: #f2f2f2 !important;
  border-radius: 10px;
  border: 1px;
  font-family: Kufam;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  color: black;
  padding: 10;
  height: 45px;
}

.arrimo_input:hover {
  border-color: #f2f2f2 !important;
}

@primary-color: black;@card-padding-base: 15px;@layout-sider-background: #fff;@menu-bg: #fff;@border-radius-base: 2px;@collapse-content-bg: #fff;@collapse-header-bg: #fff;@collapse-panel-border-radius: 10px;@layout-header-background: #f7f9fa;@heading-color: black;@label-color: #000000a6;@layout-header-height: 50px;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@table-header-color: rgba(0,0,0,0.65);@tabs-vertical-margin: 0;@font-family: Kufam;